import DataResource from "@core/resources/DataResource";
import {
    DOCUMENT_SUMMARY, 
    FAVORITES_SUMMARY
 } from "@/modules/Search/api/chatbot";

export default class Chatbot extends DataResource{
    constructor (options = {}) {
      super(options);
    }

    async getFavoritesSummary (favorites) {
        this.startLoading()
        let paramString ="";
        favorites.map(i => {
            paramString = paramString.concat(`favorites=${i.id}&`);
        });
        paramString = paramString.slice(0, -1);
        const { data } = await this.axios.get(`${FAVORITES_SUMMARY}?${paramString}`) 
        this.setData(data)
        console.log(data)
        this.stopLoading()
    }
    async getDocumentsSummary (fileId) {
        this.startLoading()
        const params = {
            file_id: fileId
        }
        console.log(params)
        const { data } = await this.axios.get(DOCUMENT_SUMMARY, { params }) 
        console.log(data)
        this.setData(data)
        this.stopLoading()
    }

}