<template>
    <v-dialog persistent v-model="favDialog" width="70%">
        <v-card class="file-upload-form ov-hidden">
        <v-card-title primary-title>
            <h2 class="title" v-text="$trans('Your Saved Searches')"></h2>
            <v-spacer></v-spacer>
            <v-btn
                small text
                icon
                @click="closeDialog()"
            ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-container fluid>
            <v-row>
                <v-col cols="12">
                <v-card color="#E0E0E0">
                    <v-card-text class="scrollable-content" :class="{'scrollable-content--active': summaryShown}">
                    <v-row>
                        <v-col cols="12">
                            <template v-for="(result, i) in favorites">
                                <category-search-result-card
                                :key="i"
                                :item="result"
                                :favorites="favorites"
                                @click:title="onResultClick"
                                @click:button="onResultClick"
                                @click:favorite="onFavoriteClick"
                                ></category-search-result-card>
                            </template>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-col class="px-0">
                <category-search-summary v-if="summaryShown" :favorites="favorites"/>
                <v-row class="mx-1 mt-1" justify="space-between">
                    <v-btn
                        class="mb-4"
                        width="30%"
                        depressed
                        large
                        @click="setSummary"
                        title="Show Summary"
                    >
                        <v-icon left small color="#F5814C">mdi-file-document-outline</v-icon>
                        <span v-text="$trans(summaryText)"></span>
                    </v-btn>
                    <v-btn
                        class="mb-4"
                        width="30%"
                        depressed
                        large
                        @click="clearFavorites"
                        title="Clear favorites"
                    >
                        <v-icon left small color="#F5814C">mdi-heart-minus</v-icon>
                        <span v-text="$trans('Remove all Favorites')"></span>
                    </v-btn>
                </v-row>
            </v-col>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default{
    props:{
        favorites: {
            type: Array,
            default: []
        },
        favDialog: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        summaryShown: false,
        summaryGenerated: false,
    }),
    computed: {
        summaryText () {
            return this.summaryShown ? "Hide Summary" : "Show Search Summary"
        }
    },
    methods: {
        onResultClick (item) {
            this.$emit('click:item', item);
            this.$router.push({
                name: 'reports.single',
                params: { slug: item.docRef },
                query: {
                page_num: item.page,
                project_type: item.type,
                data_index: item.data_index,
                back: this.$route.fullPath,
                },
            });
        },
        closeDialog(){
            this.$emit('click:close')
        },
        clearFavorites(){
            this.$emit('click:clear')
        },
        onFavoriteClick(item){
            this.$emit('click:favorite', item)
        },
        setSummary(){
            // if(!this.summaryGenerated && this.summaryShown){
            //     this.summaryGenerated = true
            // }
            this.summaryShown = !this.summaryShown
        }
    }
}
</script>

<style lang="scss" scoped>
.scrollable-content {
  max-height: 700px; /* Set the desired height */
  overflow-y: auto;

  &--active {
    max-height: 485px; /* Set the desired height */
    overflow-y: auto;
  }
}
</style>