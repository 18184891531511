<template>
    <div
      class="search-result-card__thumbnail mb-6"
    >
      <v-card>
        <v-card-text class="px-4 ov-y-auto">
          <v-row>
            <v-col cols="12">
              <div  v-if="chatbot.loading" >
                <div :style="{ whiteSpace: 'pre-line' }" class="mb-0 pb-1" v-text="'Generating summary for favorite items. Please wait...'"/>
                <v-skeleton-loader type="list-item-three-line"/>
              </div>
              <div v-else>
                <!-- <div :style="{ whiteSpace: 'pre-line' }" class="mb-0 pb-1" v-text="parsedTextHead"/>
                <v-data-table
                  :headers="parsedHeaders"
                  :items="parsedItems"
                  class="elevation-1"
                  hide-default-footer
                ></v-data-table>
                <div :style="{ whiteSpace: 'pre-line' }" class="mb-0 pb-1" v-text="parsedTextFoot"/> -->
                <div v-html="markdownText" class="markdown-content"></div>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-row justify="end" class="mx-2 my-1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mx-1"
                  v-on="on"
                >
                  <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
              </template>
              <span>This response is good</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mx-1"
                  v-on="on"
                >
                  <v-icon>mdi-message-draw</v-icon>
                </v-btn>
              </template>
              <span>This response needs review</span>
            </v-tooltip>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
</template>
  
<script>
  import Chatbot from '@/modules/Search/resources/Chatbot';
  import marked from 'marked';

  export default {
    name: 'CategorySearchSummary',
    props: {
      favorites:{
        type: Array,
        default: []
      },
      generated: {
        type: Boolean,
        default: false
      }
    },
  
    data: () => ({
      chatbot: new Chatbot,
      parsedTextHead: '',
      parsedTextFoot: '',
      parsedHeaders: [],
      parsedItems: [],
    }),

    async mounted(){
      if(!this.generated){
        try{
          await this.chatbot.getFavoritesSummary(this.favorites)
        } finally {
          this.parseText()
        }
      }
    },
  
    computed: {
      displayText(){
        if(this.chatbot.loaded){
          return this.parsedTextHead
        }
        return "Response generating. Please wait..."
      },
      markdownText(){
        marked.setOptions({
          gfm: true,
          breaks: true
        });
        return marked(this.chatbot.data.data.summary)
      }
    },
  
    methods: {
      parseText(){
        const tableRegex = /\|.*\|/s;
        const inputString = this.chatbot.data.data.summary

        const tableString = inputString.match(tableRegex);

        if (tableString) {
          const [beforeTable, afterTable] = inputString.split(tableString[0]);
          this.parsedTextHead = beforeTable
          this.parsedTextFoot = afterTable
        } else {
          console.error('No table found in the string');
        }

        const lines = tableString[0].split('\n');

        const headerLine = lines[0];
        const headers = headerLine.split('|').map(header => header.trim()).filter(header => header);

        const dataLines = lines.slice(2);

        const items = dataLines.map(line => {
          const row = line.split('|').map(item => item.trim()).filter(item => item);
          const item = {};
          headers.forEach((header, index) => {
            item[header.toLowerCase().replace(/\s+/g, '')] = row[index];
          });
          return item;
        });
        this.parsedHeaders = headers.map(header => ({
          text: header,
          value: header.toLowerCase().replace(/\s+/g, '') // Use header as the item key
        }));
        this.parsedItems = items;
      }
    },
  };
</script>

<style>
.search-result-card__thumbnail {
  border: 3px solid transparent;
  transition: all 0.05s ease-in;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid rgb(221, 221, 221);
  padding: 8px;
}

.markdown-content th {
  background-color: rgb(242, 242, 242);
  font-weight: bold;
  text-align: left;
}

.markdown-content tr:nth-child(even) {
  background-color: rgb(249, 249, 249);
}
</style>