<template>
  <div>
    <v-btn outlined @click="generateSummary">
      Generate Summary
    </v-btn>
    <template v-if="hasData">
      <v-simple-table class="v-data-table--denser">
        <tbody>
          <tr v-for="(row, i) in rows" :key="i">
            <td
              class="caption text-no-wrap font-weight-bold text-uppercase"
              v-text="$trans(row.text)"
            ></td>
            <td class="caption" v-text="row.value"></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
    <template v-else>
      <general-summary-info-table-empty-state v-if="!generated"></general-summary-info-table-empty-state>
      <div v-if="chatbot.loading">
        <v-skeleton-loader type="list-item-three-line"/>
      </div>
      <div v-if="chatbot.loaded && generated" class="py-3">
        <!-- <body :style="{ whiteSpace: 'pre-line' }">{{ chatbot.data.data.summary }}</body> -->
        <div v-html="markdownText"></div>
      </div>
    </template>
    <v-row justify="end" class="mx-2 my-1">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            class="mx-1"
            v-on="on"
          >
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
        </template>
        <span>This response is good</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            class="mx-1"
            v-on="on"
          >
            <v-icon>mdi-message-draw</v-icon>
          </v-btn>
        </template>
        <span>This response needs review</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Chatbot from '@/modules/Search/resources/Chatbot';
import marked from 'marked';

export default {
  name: 'GeneralSummaryInfoTable',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: vm => ({
    well: vm.item.data,
    chatbot: new Chatbot,
    generated: false,
  }),

  computed: {
    hasData () {
      return !isEmpty(this.well);
    },

    rows () {
      return [
        {
          text: 'Project name',
          value: this.well.project_name,
        },
        {
          text: 'Company',
          value: this.well.company,
        },
        {
          text: 'Field',
          value: this.well.field,
        },
        {
          text: 'Start',
          value: this.well['start date'],
        },
        {
          text: 'End',
          value: this.well['end date'],
        },
        {
          text: 'Platform',
          value: this.well.platform,
        },
      ];
    },
    markdownText(){
      console.log(this.chatbot.data.data.summary)
      marked.setOptions({
        gfm: true,
        // breaks: true
      });
      return marked(this.chatbot.data.data.summary)
    }
  },

  methods: {
    generateSummary(){
      // console.log(this.$route.params.slug)
      if (!this.generated){this.generated = true;}
      this.chatbot.getDocumentsSummary(this.$route.params.slug)
    }
  }
};
</script>
